import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { ElMessage } from 'element-plus'

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/keydate",
		name: "keydate",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/KeyDateView.vue"),
	},
	{
		path: "/sqftanalyst",
		name: "sqftanalyst",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/SqftAnalystView.vue"),
	},
	{
		path: "/financial",
		name: "financial",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/FinancialView.vue"),
	},
	{
		path: "/manager",
		name: "manager",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/ManagerView.vue"),
	},
	{
		path: "/login",
		name: "login",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/LoginTest.vue"),
	},
	{
		path: "/test",
		name: "test",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/FinancialtestView.vue"),
	},
	{
		path: "/taskReminder",
		name: "taskReminder",
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/TaskReminder.vue"),
	},
	{
		path: '/FinancialTrackingClientView',
		name: 'FinancialTrackingClientView',
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/CustomFinancial.vue'),
		meta: {
			userType: 'external'
		},
	},
	// {
	// 	path: '/interactiveTest',
	// 	name: 'interactiveTest',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (About.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import('../views/InteractiveTest.vue'),
	// 	meta: {
	// 	}
	// },
	{
		path: '/openHouse',
		name: 'openHouse',
		// route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/OpenHouse.vue"),
		meta: {
			userType: 'external'
		}
	}
];

const router = createRouter({
	history: createWebHistory('/'),
	routes,
})

// 路由守卫
router.beforeEach((to, from) => {
	if (to.name !== 'login') {
		console.log(to.name);
		if(to.name === 'openHouse') {
			return
		} else {
			if (!JSON.parse(localStorage.getItem('isLogin'))) {
				return '/login'
			}
			if (JSON.parse(localStorage.getItem('user'))['userType'] === 'external') {
				if (to.meta.userType !== 'external') {
					ElMessage({
						type: "warning",
						message: "You are currently an external user and cannot browse other pages!"
					})
					return from.path
				}
			}
		}
	}
})

export default router



